/*.container {*/
/*    display:flex;*/
/*    text-align: center;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*.container2 {*/
/*    display:flex;*/
/*    text-align: left;*/
/*    justify-content: left;*/
/*    align-items: flex-start;*/
/*    flex-direction: column;*/

/*}*/
/*.container3 {*/
/*    display:flex;*/
/*    text-align: left;*/
/*    justify-content: left;*/
/*    align-items: flex-start;*/

/*}*/

/*.margin2 {*/
/*    margin-bottom: 1em;*/

/*}*/

.login-div {
    margin-top: 50px;
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.login-input-label {
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.login-input {
    display: flex;
    flex-direction: column;
}

.login-label {
    display: flex;
    flex-direction: column;
}

.login-label label{
    padding: 8px;
    color: lightslategray;
    margin-bottom: 10px;
}

.login-input input {
    padding: 8px;
    margin-left: 10px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    min-width: 250px;
    margin-bottom: 10px;
}

.login-button{
    margin-top: 30px;
}

.login-button button{
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

.login-error{
    padding-left: 8px;
    margin-top: 85px;
}