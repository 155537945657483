
.container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline
}

.section {
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 10px;
    color: #333;
}

.section-level1 {
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 0px;
    color: #333;
}

.sectionHeader {
    padding: 10px;
    font-weight: bold;
    cursor: move;
    z-index: 10;
    color: #fff;
    background: linear-gradient(90deg, #0388e5 0, #07bdf4);
}

/*/https://html-cleaner.com/features/replace-html-table-tags-with-divs/*/
.rTable {
    display: table;
    width: 100%;
}

.rTableRow {
    display: table-row;
}

.rTableCell {
    display: table-cell;
    padding: 3px 10px;
    text-align: left;
    vertical-align: middle;
    /*word-wrap: break-word;*/
}

.argumentHeader {
    font-weight: bold;
    text-align: left;
    /*word-wrap: break-word;*/
    max-width: 200px;
}

/*https://stackoverflow.com/questions/10476632/how-to-scroll-the-page-when-a-modal-dialog-is-longer-than-the-screen*/
.modalScrollHeight {
    max-height: calc(100vh - 20px);
    overflow-y: auto;
}

.textArea {
    font-size: 11px;
    resize: both;
    min-width: 150px;
    color: #333;
}

button {
    font-size: 11px;
}

.rTableHeaderGroupRow {
    display: table-header-group;
}
