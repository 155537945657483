.App {
    text-align: center;

}

/*.App-logo {*/
/*  animation: App-logo-spin infinite 5s linear;*/
/*  height: 10vmin;*/
/*  pointer-events: none;*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    !*transform: rotate(0deg);*!*/
/*    transform: scale(0.2) rotate3d(0);*/
/*  }*/
/*  to {*/
/*    !*transform: rotate(360deg);*!*/
/*    transform: scale(2) rotate3d(1, 1, 1, 360deg);*/
/*  }*/
/*}*/

/** {*/
/*  box-sizing: border-box;*/
/*}*/
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

/*.inputfile + label {*/
/*  !*display: inline-block;*!*/
/*  background-color: #4CAF50;*/
/*  color: white;*/
/*  padding: 10px 20px;*/
/*  border: none;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*  float: right;*/
/*  display: block;*/
/*  width: 100%;*/
/*}*/


body {
    /*font-family: Arial, Helvetica, sans-serif;*/
    /*background-color: lavender;*/
    font-size: 11px;
    font-family: Open Sans, Helvetica Neue, sans-serif;
    margin: 0px;
}


body .p-inputtext {
    font-size: 11px !important;
}

/*Dla dialogu : Nowe zlecenie - numer zlecenia wychodził poza obszar dialogu*/
body .p-growl {
    min-width: 16%;

}


width100 {
    width: 100%;
}

.p-datatable-row td {
    text-align: center;
}

/*td,th{*/
/*  word-wrap:break-word*/
/*}*/

body .p-checkbox {
    width: 20px !important;
}

/* Style the header */
.header-wrapper {
    margin-bottom: 5px;
}

.header {
    text-align: center;
    background: linear-gradient(90deg, #0388e5 0, #07bdf4);
}

body .p-datepicker table {
    font-size: 11px !important;
}

body .p-component {
    font-size: 11px !important;
}

body .p-panelmenu .p-panelmenu-header > a {
    font-size: 12px !important;
}

.browser-warning {
    background: linear-gradient(90deg, #f7271c 0, #f7761c);
    color: #fff;
    padding: 10px !important;
}

/* Create three unequal columns that floats next to each other */
/*.column {*/
/*  float: left;*/
/*  padding: 10px;*/

/*}*/

/* Left and right column */
/*.column.side {*/
/*  width: 20%;*/
/*}*/

/*!* Middle column *!*/
/*.column.middle {*/
/*  width: 80%;*/

/*}*/

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Style the footer */
.footer {
    text-align: right;
    bottom: 0;
    position: fixed;
    background-color: lavenderblush;
}

.footerElement {
    margin-right: 10px;
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
    .column.side, .column.middle {
        /*width: 100%;*/
    }
}

/*dd{*/
/*background-color: red;*/
/*  float: left;*/
/*}*/

.filter-panel {
    /*margin-top: 1em;*/
    margin-bottom: 0.5em;
    /* margin-left: 0.5em; */
}

.filter-panel-field-div {
    display: flex;
    /*justify-content: left;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*align-items: flex-start;*/
    /*padding-bottom: 1em;*/
    /*padding-top: 1em;*/
    /*flex-direction: row;*/
    margin-bottom: 0.5em;
    flex-wrap: wrap;


}


.filter-panel-div {
    /*display: flex;*/
    /*justify-content: left;*/
    /*align-items: center;*/
    /*min-width: 25%;*/
    /*border-style: groove;*/
    border: 1px solid #c8c8c8;
    border-radius: 0.2em;
    /*text-align: center;*/
    padding: 5px;
    display: flex;
    /*align-items: start;*/
    /*justify-content: center;*/
    min-width: 400px;
    margin-bottom: 0.15em;
    margin-right: 0.15em;

}


.filter-panel-label {
    min-width: 110px;
    max-width: 110px;
    padding: 5px;
    color: #007ad9;
}

.hideTimePicker .p-datepicker > .p-timepicker {
    visibility: hidden;
    max-height: 1px;
}

.filter-panel-input {
    min-width: 275px;
    max-width: 275px;
    /*width: 450px;*/
}

.filter-panel-input input {
    /*width: 450px;*/
    /*min-width: 275px;*/
    /*max-width: 275px;*/
}

.p-calendar .p-inputtext {
    /*width: 450px;*/
    min-width: 275px;
    max-width: 275px;
}

.too-many-orders-warn {
    font-size: 14px;
    font-weight: bold;
    display: inline-flex;
    text-align: center;
    padding: 5px;
    line-height: 2;
}


.filter-panel-div > .filter-panel-input {
    width: 275px;
}

/*Filtrowanie zleceń status*/
.filter-panel-input .p-multiselect-label {
    min-width: 275px;
    max-width: 275px;
    /*min-width: 450px;*/
}

.filter-panel-input .p-autocomplete-token-label {
    /* color: black; */

    font-size: 11px;
    /*min-width: 450px;*/
}

.filter-panel-input .p-autocomplete-input-token input {
    font-size: 11px !important;
    /* width: 20px; */
}

.filter-panel-input .p-autocomplete-token {
    /* background: white !important; */
    font-size: 11px !important;
    margin-bottom: 2px !important;
    margin-right: 2px !important;
}


.filter-panel-input .p-autocomplete-multiple-container {
    width: 275px;
    margin-bottom: 10px;
}

.filter-panel-input .p-autocomplete-multiple-container .p-autocomplete-token {
    /* width: 265px; */
    margin-bottom: 10px;
}


/*
.filter-panel-field-div {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  padding-bottom: 1em;
  padding-top: 1em;
  flex-direction: row;
  flex-wrap: wrap;


}


.filter-panel-div {
  display: flex;
  justify-content: left;
  align-items: center;
  min-width: 25%;
  border-style: hidden;
  padding: 10px;

}


.filter-panel-label{
  min-width: 110px;
  max-width: 110px;
  color: #007ad9;
}

.hideTimePicker .p-datepicker > .p-timepicker {
  visibility: hidden;
  max-height: 1px;
}

.filter-panel-input{
  width: 450px;
}
.filter-panel-input input{
  width: 450px;
}

Filtrowanie zleceń status
.filter-panel-input .p-multiselect-label{
  min-width: 450px;
}
 */


/**/
.blue {
    background-color: blue;
    border-style: solid;
    padding: 10px;
}

.o-label {
    text-align: right;
    margin: 5px 5px 0px 5px;
    /*background: #efefef;*/
    background: white;
    border-radius: 4px;
    padding: 5px;
    /*color: lightslategray;*/
    color: #333333;
}

.o-value {
    margin: 5px 5px 0px 5px;
    padding: 5px;
    display: table-cell;
    vertical-align: middle;
}

.container {
    /*margin-top: fill;*/
    /*background: #efefef;*/
    /*background: #f4f4f4;*/
    /*border-radius: 4px;*/
    /*background-color: red;*/
}

.mm {
    /*background-color: green;*/
    padding-top: 0px;
}

.label {
    text-align: right;
    margin: 0px 5px 0px 5px;
    background: white;
    border-radius: 4px;
    padding: 5px;
    color: #333333;
}

.button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    margin-left: 5px;
    margin-top: 5px;
    display: block;
}

.call-button {
    background-color: #4CAF50;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: 5px;
}

.pi-copy {
    padding: 3px !important;
}

.marginRight {
    margin-right: 10px;
}

.margin-right-assign {
    margin-right: 20px;
}

.dialogFooter {
    display: inline-flex;
}

.call-pt-dialog {
    min-width: 10vw;
    max-width: 25vw;
    text-align: center;
}

.pt-number-warn {
    font-size: 10px;
    color: red;
    margin-top: 15px;
    border-top: gray 1px solid;
    text-align: center;
    padding: 5px;
}

.call-error {
    text-align: left;
}

.validation-msg {
    margin-left: 5px;
}

.width250 {
    width: 250px;
}

.commentTextArea {
    font-size: 11px;
    resize: both;
    min-width: 125px;
    min-height: 50px;
    color: #333;
}

body .p-dropdown .p-dropdown-label {
    padding-right: 3em !important;
}

.pm-warning {
    border-color: red !important
}

.noData {
    color: #5B5D74;
    font-weight: bold;
}

.distinction {
    font-weight: bold;
    background-color: #FFA500;
    font-size: 13px;
}

.defaultCursor {
    cursor: default;
}

.buttonL {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    float: right;
    margin-left: 5px;
    margin-top: 2px;
    display: block;
}

.equal {
    background-color: #9ada53 !important;
}

.notEqual {
    background-color: #fb3c3c !important;
}

.marginTopBottom {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-top: 1px solid rgb(0, 0, 0, 0.3);
}

.divInlineFlexFooter {
    display: inline-flex;
    margin-top: 5px;
}

.distinctionTableCell {
    font-weight: bold;
    background-color: #FFA500;
    font-size: 13px;
    display: table-cell;
}

.bold {
    font-weight: bold;
}

.orange {
    color: #ff7900;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight > td > div > a {
    color: #ffffff;
    font-weight: bold;
}


.chat-label {
    font-weight: bold;
    border-radius: 15px;
    padding: 4px;
    display: inline-flex;
    margin: 2px;
    font-size: 10px;
    cursor: alias;
}

.not-opened-chat-label {
    color: #ffffff;
    background-color: #d32f2f;
    border: 1px solid #d32f2f;
}

.unread-messages-chat-label {
    color: #21252a;
    background-color: #fbc02d;
    border: 1px solid #fbc02d;
}

.no-unread-messages-chat-label {
    color: white;
    background-color: #689f38;
    border: 1px solid #689f38;
}

.open-chat {
    cursor: pointer;
}

.marginBottom {
    margin-bottom: 10px;
}

.marginTop0 {
    margin-top: 0px;
}

.rigthHeader .p-overlaypanel::after, .rigthHeader .p-overlaypanel::before {
    left: unset !important;
    right: 0.25em;
}

.p-error {
    color: #a90000;
}

.p-inputtext.p-invalid.p-component {
    color: #a90000;
    border-color: #a90000;
}

.p-inputtext.p-invalid.p-component:enabled:focus {
    color: #a90000;
    border-color: #a90000;
    box-shadow: 0 0 0 0.2em #b86161;
}
