.p-button.badge:after {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    background: #f7761c;
    border-radius: 50%;
    content: '';
}

.high-priority {
    background-color: #ED4337 !important;
}